<template>
  <div name="collagenumber">
    <top :name="text" />
    <div class="ul_div" style="display: inline-block; width: 100%">
      <ul class="commend-list">
        <li
          style="
            border-right: 1px solid #dcdcdc;
            border-bottom: 1px solid #dcdcdc;
          "
          @click="collagedot(item.id)"
          v-for="(item, index) in json_data"
          :key="index"
        >
          <div class="commend-img">
            <img class="" :src="item.group_image" alt="" />
          </div>
          <h4
            style="
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            "
          >
            <span
              style="
                padding: 0.02rem 0.1rem 0.02rem 0.1rem;
                border: 1px solid red;
                color: red;
              "
              >{{ item.count_user }}人团</span
            >{{ item.group_name }}
          </h4>
          <div>
            <p>{{ item.deliver_user }}人得商品发货</p>
            <p>
              {{ item.refund_user }}人退款并得{{
                item.comfort_amount
              }}.00元安慰奖
            </p>
          </div>
          <div class="commend-sale" style="clear: both">
            <span
              style="
                color: red;
                float: left;
                font-size: 0.3rem;
                font-weight: 700;
              "
              >{{ item.display_price }}元</span
            >
            <span style="float: right">销量{{ item.sales_volume }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import top from "../components/filltop.vue";
export default {
  data() {
    return {
      text: `${
        this.$route.query.id == "all"
          ? "全部团购商品"
          : this.$route.query.id + "人拼团购"
      }`,
      id: 0,
      json_data: {},
      // 分页查询
      page: 1,
    };
  },
  components: {
    top,
  },
  mounted() {
    this.id = this.$route.query.id;
    this.$https(
      "collage/list",
      "id=" +
        sessionStorage.getItem("user_id") +
        "&phone=" +
        sessionStorage.getItem("user_phone") +
        "&page=" +
        this.page +
        "&static=" +
        this.$route.query.id,
      "post"
    ).then((res) => {
      this.json_data = res.data.data;
    });
  },
  methods: {
    collagedot(id) {
      this.$https(
        "collage/deleta",
        "id=" +
          sessionStorage.getItem("user_id") +
          "&phone=" +
          sessionStorage.getItem("user_phone") +
          "&collage=" +
          id,
        "post"
      ).then((res) => {
        console.log(JSON.parse(res.data.data.json_template));
        if (!(res.data.msg == "正常响应，数据为空")) {
          this.$router.push({
            name: "collagedot",
            params: { success: res.data.data },
          });
        } else {
          // 提示错误
        }
      });
    },
  },
};
</script>

<style src="../viewscss/viewscss.css" scoped>
</style>