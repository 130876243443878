import { render, staticRenderFns } from "./collagenumber.vue?vue&type=template&id=ac664ffa&scoped=true&"
import script from "./collagenumber.vue?vue&type=script&lang=js&"
export * from "./collagenumber.vue?vue&type=script&lang=js&"
import style0 from "../viewscss/viewscss.css?vue&type=style&index=0&id=ac664ffa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac664ffa",
  null
  
)

export default component.exports